import I18n from 'i18n-js'
import './translations'
import moment from 'moment'

I18n.defaultLocale = 'lv'
I18n.locale = document.documentElement.getAttribute('lang')

moment.locale(I18n.locale)

export default I18n
export const t = I18n.t.bind(I18n)
