import * as Sentry from '@sentry/react'
import { getMetaValue } from 'utils'

const environment = getMetaValue('environment')
const dsn = getMetaValue('sentry-dsn')

window.sentry = null
const initializeSentry = async () => {

  const config = {
    dsn: dsn,
    environment: environment,
    tracesSampleRate: 0,
    ignoreErrors: ["canceled"] // match the error message for them to be filtered out
  };

  await Sentry.init(config)
  window.sentry  = Sentry
}

initializeSentry();
